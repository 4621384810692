<template>
  <section class="relative h-full bg-login bg-cover bg-center">
    <div class="w-full relative">
      <div
        class="
          container
          px-5
          py-24
          mx-auto
          flex flex-wrap
          items-center
          min-h-screen
        "
      >
        <div class="lg:w-3/5 md:w-1/2 lg:pr-52 pr-0 hidden lg:block">
          <h1 class="lg:text-5xl text-3xl text-left text-secondary font-ligh">
            Bildin, la mejor plataforma para gestionar tu obra
          </h1>
          <div class="text-left pt-5 font-light text-secondary text-base">
            <li class="pb-1">Tareo</li>
            <li class="pb-1">Planificación</li>
            <li class="pb-1">Planos</li>
          </div>
        </div>
        <div
          class="
            lg:w-2/6
            md:w-1/2
            p-8
            flex flex-col
            md:ml-auto
            w-full
            lg:mt-10
            md:mt-0
          "
        >
          <div class="text-center mb-12">
            <router-link :to="{ name: 'login' }">
              <img src="@/assets/bildin-logo.svg" height="56" class="mx-auto" />
            </router-link>
          </div>

          <slot></slot>
        </div>
      </div>
    </div>
  </section>
</template>

<style>
.bg-login {
  background-image: url("~@/assets/fond-login.png");
}
</style>
