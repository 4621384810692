<template>
  <AuthLayout>
    <div v-if="loadingConfirm" class="flex mx-auto py-5">
      <LoadingButtonIcon color="gray" />
      Confirmando correo electrónico
    </div>
    <Alert
      v-if="responseMessage"
      :type="responseType"
      :text="responseMessage"
    ></Alert>
    <div v-if="responseType === 'success'">
      <button
        class="rounded bg-primary py-2 px-4 text-white w-full"
        @click="$router.push({ name: 'login' })"
      >
        Iniciar sesión
      </button>
    </div>
  </AuthLayout>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import AuthLayout from "@/layouts/Auth";

const router = useRouter();
const store = useStore();

const loadingConfirm = ref(false);
const responseType = ref(null);
const responseMessage = ref(null);

const confirmEmail = async ({ hash }) => {
  try {
    loadingConfirm.value = true;
    responseType.value = null;
    responseMessage.value = null;

    await store.dispatch("confirmEmail", { hash });

    responseType.value = "success";
    responseMessage.value =
      "Se confirmo el correo electrónico con éxito, ahora ya puede iniciar sesión en Bildin";
  } catch (error) {
    responseType.value = "error";
    responseMessage.value =
      error.message ||
      "Ha ocurrido un error al confirmar el correo electrónico";

    console.error(error);
  } finally {
    loadingConfirm.value = false;
  }
};

const init = () => {
  confirmEmail({ hash: router.currentRoute.value.query?.hash });
};

init();
</script>
